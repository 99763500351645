.faq {
  padding: 80px 0px 160px;
  &__title {
    &:not(:last-child) {
      margin-bottom: 64px;
    }
  }
  &__block {
    max-width: 828px;
    margin: 0 auto;
    & .faq-item:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  @media (max-width: $mobile) {
    padding: 80px 0px 80px;
    &__title {
      &:not(:last-child) {
        margin-bottom: 23px;
      }
    }
    &__block {
      & .faq-item:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}