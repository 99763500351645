@mixin fontRuble($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
		font-family: $f-name;
		font-style:  $f-style;
		font-weight: $f-weight;
		src: url('../fonts/#{$f-url}.ttf') format('truetype');
	}
}
@mixin font($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
	  font-family: $f-name;
	  font-style:  $f-style;
	  font-weight: $f-weight;
	  src: url('../fonts/#{$f-url}.woff2') format('woff2'),
	  url('../fonts/#{$f-url}.woff') format('woff'),
	  url('../fonts/#{$f-url}.ttf') format('truetype'),
	  url('../fonts/#{$f-url}.eot'),
	  url('../fonts/#{$f-url}.eot?#iefix') format('embedded-opentype');
	}
}

@include font('Open Sans', normal, 400, 'open-sans/open-sans-v17-latin_cyrillic-regular');
@include font('Mulish', normal, 400, 'mulish/Mulish-Regular');
@include font('Mulish', normal, 700, 'mulish/Mulish-Bold');
@include font('Mulish', normal, 800, 'mulish/Mulish-ExtraBold');