.exchanges {
  padding: 80px 0px 160px;
  &__body {
    background-color: #F6F8FA;
    border-radius: 48px;
    padding: 81px 72px 80px 108px;
    display: flex;
    column-gap: 106px;
  }
  &__left {
    text-align: start;
    flex-basis: 504px;
  }
  &__logo {
    margin-bottom: 19px;
  }
  &__title {
    margin-bottom: 24px;
    color: $text-color;

  }
  &__text {
    color: #728A96;
    font-size: 20px;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
  &__button {
    text-transform: inherit;
  }
  &__img {
    width: 120px;
    height: 120px;
    border-radius: 37px;
    box-shadow: 0px 20px 35px rgba(0, 0, 0, .1);
  }
  @media (min-width: $desktop) {
    &__right {
      position: relative;
      flex-basis: 469px;
    }
    &__img {
      position: absolute;
      &_1 {
        top: -0.8%;
        left: 2.98%;
      }
      &_2 {
        top: 24.17%;
        left: 36.87%;
      }
      &_3 {
        top: 10.18%;
        right: 0;
      }
      &_4 {
        top: 179px;
        left: 0;
      }
      &_5 {
        bottom: 8.76%;
        right: 18.74%;
      }
    }
  }
  @media (max-width: $desktop) {
    &__right {
      display: flex;
      column-gap: 24px;
      flex-wrap: wrap;
      flex-basis: 50%;
    }
  }
  @media (max-width: $tablet) {
    padding: 80px 0px 80px;
    &__body {
      flex-direction: column-reverse;
      row-gap: 30px;
    }
    &__right {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      flex-basis: 50%;
      align-items: center;
      justify-content: center;
    }
    &__left {
      text-align: center;
      flex-basis: content;
      align-items: center;
    }
    &__button.btn {
      width: 303px;
    }
  }
  @media (max-width: $mobile-big) {
    padding: 0;
    background-color: #F6F8FA;
    &__body {
      padding: 40px 4px;
    }

  }
  @media (max-width: $mobile) {
    &__body {
      row-gap: 32px;
    }
    &__img {
      width: 76.6px;
      height: 76.6px;
      border-radius: 37px;
      box-shadow: 0 15px 25px rgba(0, 0, 0,  .15);
    }
    &__title {
      margin-bottom: 16px;
    }
    &__text {
      font-size: 16px;
      &:not(:last-child) {
        margin-bottom: 23px;
      }
    }
  }
  @media (max-width: 374px) {
    &__right {
      gap: 20px;
    }
    &__button.btn {
      width: 280px;
    }
  }
}