@charset "UTF-8";
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@import url(swiper/swiper-bundle.css);
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-v17-latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v17-latin_cyrillic-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-v17-latin_cyrillic-regular.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v17-latin_cyrillic-regular.eot"), url("../fonts/open-sans/open-sans-v17-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/mulish/Mulish-Regular.woff2") format("woff2"), url("../fonts/mulish/Mulish-Regular.woff") format("woff"), url("../fonts/mulish/Mulish-Regular.ttf") format("truetype"), url("../fonts/mulish/Mulish-Regular.eot"), url("../fonts/mulish/Mulish-Regular.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/mulish/Mulish-Bold.woff2") format("woff2"), url("../fonts/mulish/Mulish-Bold.woff") format("woff"), url("../fonts/mulish/Mulish-Bold.ttf") format("truetype"), url("../fonts/mulish/Mulish-Bold.eot"), url("../fonts/mulish/Mulish-Bold.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/mulish/Mulish-ExtraBold.woff2") format("woff2"), url("../fonts/mulish/Mulish-ExtraBold.woff") format("woff"), url("../fonts/mulish/Mulish-ExtraBold.ttf") format("truetype"), url("../fonts/mulish/Mulish-ExtraBold.eot"), url("../fonts/mulish/Mulish-ExtraBold.eot?#iefix") format("embedded-opentype"); }

/* smartphones, touchscreens */
/* stylus-based screens */
/* Nintendo Wii controller, Microsoft Kinect */
/* mouse, touch pad */
/* hide scrollbar */
@-ms-viewport {
  width: device-width; }

[data-whatintent='touch'] *:focus,
[data-whatintent='mouse'] *:focus {
  outline: none; }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

*::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; }

body {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  overflow-x: hidden;
  text-align: left;
  cursor: default;
  word-break: break-word;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (max-width: 767px) {
    body {
      font-size: 14px; } }

body.lock {
  overflow: hidden; }

[tabindex='-1']:focus {
  outline: none !important; }

[role='button'] {
  cursor: pointer; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0; }

a {
  text-decoration: none;
  color: inherit; }

button,
input {
  overflow: visible;
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

input[type=submit] {
  width: auto; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  resize: vertical; }

svg {
  fill: currentColor; }
  a svg,
  button svg {
    pointer-events: none; }
  svg:not(:root) {
    overflow: hidden; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #b3b3b3; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0; }

p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0; }

address {
  font-style: normal; }

ul, ol {
  margin: 0; }
  ul.no-style, ol.no-style {
    padding: 0;
    list-style: none; }

li {
  line-height: inherit; }

blockquote {
  padding: 0;
  margin: 0; }

code {
  padding: 0.06em 0.3em;
  color: #000;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px; }

kbd {
  padding: 0.06em 0.3em;
  color: #000;
  background-color: #e6e6e6;
  border-radius: 3px; }
  kbd kbd {
    padding: 0; }

pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08); }
  pre code {
    background: transparent; }

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
  /* For Firefox */ }

select {
  appearance: none; }
  select::-ms-expand {
    display: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0; }

h1 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800; }

h2 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800; }

h3 {
  font-size: 28px;
  line-height: 34px; }

h4 {
  font-size: 22px; }

.container {
  max-width: 1320px;
  width: 100%;
  margin: auto;
  padding: 0 30px; }
  @media (max-width: 575px) {
    .container {
      padding: 0 20px; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.apple-fix {
  width: 100%;
  position: fixed; }

.no-scroll {
  overflow: hidden; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-x: hidden;
  position: relative; }
  .wrapper.sticky {
    overflow: unset; }
  .wrapper .header {
    flex: 0 0 auto; }
  .wrapper main {
    position: relative;
    flex: 1 0 auto; }
  .wrapper .footer {
    flex: 0 0 auto; }

.table {
  display: table;
  width: 100%;
  border: 1px solid #b3b3b3;
  border-collapse: collapse; }
  .table td,
  .table th {
    padding: 5px 10px;
    vertical-align: top;
    border-bottom: 1px solid #b3b3b3; }
    .table td:not(:first-child),
    .table th:not(:first-child) {
      border-left: 1px solid #b3b3b3; }
  .table th {
    text-align: inherit; }

.is-default-hidden {
  display: none !important; }

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 20px;
  height: 72px;
  border: none;
  border-radius: 124px;
  cursor: pointer;
  color: #fff;
  background-color: #0EA5F1;
  margin: 0;
  padding: 0 36px;
  z-index: 1;
  transition: .25s linear;
  transition-property: color, background-color, border-color;
  font-weight: 700; }
  @media (hover: hover) {
    .btn:hover {
      background-color: #fff;
      color: #0EA5F1; } }
  .btn:active {
    background-color: #fff;
    color: #0EA5F1; }
  .btn.disabled, .btn[disabled] {
    pointer-events: none;
    opacity: .5; }
  .btn--secondary {
    color: cornflowerblue;
    background-color: transparent; }
    @media (hover: hover) {
      .btn--secondary:hover {
        color: #fff;
        background-color: cornflowerblue; } }

.btn-inline {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0; }
  .btn-inline svg {
    fill: cornflowerblue;
    margin-right: 12px; }

@media (max-width: 991px) {
  .btn {
    height: 52px; } }

@media (max-width: 575px) {
  .btn {
    font-size: 14px; } }

.card-chat {
  background-color: #F6F8FA;
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-basis: 324px; }
  .card-chat__body {
    padding: 0px 36px 64px;
    font-size: 24px;
    font-weight: 800;
    line-height: 133%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-chat__tgs {
    max-width: 212px;
    max-height: 212px;
    margin-top: -65px; }
  .card-chat__text {
    padding-top: 24px; }
  @media (max-width: 575px) {
    .card-chat__body {
      padding: 0px 36px 36px;
      font-size: 20px;
      line-height: 150%; }
    .card-chat__tgs {
      max-width: 143px;
      max-height: 144px;
      margin-top: -47px; }
    .card-chat__text {
      padding-top: 16px; } }

.faq-item {
  background-color: #F6F8FA;
  border-radius: 48px;
  padding: 36px 48px; }
  .faq-item__title {
    font-size: 24px;
    line-height: 133%;
    font-weight: 800; }
    .faq-item__title:not(:last-child) {
      margin-bottom: 16px; }
  .faq-item__text {
    color: #728A96;
    font-size: 20px; }
  @media (max-width: 575px) {
    .faq-item {
      padding: 36px 28px; }
      .faq-item__title {
        font-size: 16px;
        line-height: 150%; }
      .faq-item__text {
        font-size: 16px; } }

.message {
  background: #38383C;
  border-radius: 16px; }
  .message__body {
    padding: 16px;
    display: flex;
    column-gap: 12px;
    font-size: 14px;
    line-height: 129%; }
  .message__logo {
    width: 36px;
    height: 36px; }
  .message__top {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    opacity: 0.5;
    padding-bottom: 2px; }
  .message__title {
    font-weight: 800;
    padding-bottom: 2px; }
  .message__text {
    margin-bottom: 12px; }
  .message__sum {
    font-weight: 800;
    color: #0EA5F1; }
  @media (max-width: 575px) {
    .message__top {
      font-size: 12px; }
    .message__title {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
    .message__text {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
    .message__sum {
      font-size: 12px; } }

.subscription-card {
  background-color: #F6F8FA;
  border-radius: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 324px;
  padding-bottom: 80px; }
  .subscription-card__img {
    max-width: 120px;
    max-height: 120px;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    border-radius: 37px;
    margin-top: -46px;
    margin-bottom: 36px; }
  .subscription-card__link {
    color: #0EA5F1;
    font-size: 24px;
    font-weight: 800;
    line-height: 133%;
    margin-bottom: 36px; }
  .subscription-card__sum {
    font-size: 56px;
    line-height: 116%;
    font-weight: 800; }
  .subscription-card__time {
    font-size: 20px;
    color: #728A96; }
    .subscription-card__time:not(:last-child) {
      margin-bottom: 48px; }
  .subscription-card__button {
    text-transform: inherit; }
  @media (max-width: 767px) {
    .subscription-card {
      padding-bottom: 60px; }
      .subscription-card__sum {
        font-size: 36px; }
      .subscription-card__time:not(:last-child) {
        margin-bottom: 38px; } }
  @media (max-width: 575px) {
    .subscription-card {
      padding-bottom: 36px; }
      .subscription-card__img {
        max-width: 96px;
        max-height: 96px;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.15);
        margin-top: -36px;
        margin-bottom: 19px; }
      .subscription-card__link {
        font-size: 20px;
        margin-bottom: 26px; }
      .subscription-card__sum {
        font-size: 32px;
        line-height: 125%; }
      .subscription-card__time {
        font-size: 16px; }
        .subscription-card__time:not(:last-child) {
          margin-bottom: 30px; }
      .subscription-card__button {
        width: 252px; } }

.title {
  font-size: 56px;
  font-weight: 800;
  line-height: 114%; }
  .title__center {
    text-align: center;
    max-width: 828px;
    margin: 0 auto; }
  @media (max-width: 767px) {
    .title {
      font-size: 46px; } }
  @media (max-width: 575px) {
    .title {
      font-size: 36px; } }
  @media (max-width: 479px) {
    .title {
      font-size: 32px;
      line-height: 125%;
      text-align: center; } }
  @media (max-width: 374px) {
    .title {
      font-size: 28px; } }

.chat-orders {
  padding: 160px 0px 80px; }
  .chat-orders__title {
    color: #000;
    margin-bottom: 24px; }
    .chat-orders__title br {
      display: none; }
  .chat-orders__text {
    font-size: 20px;
    color: #728A96;
    text-align: center;
    margin: 0 auto;
    max-width: 612px; }
    .chat-orders__text br {
      display: none; }
    .chat-orders__text a {
      color: #0EA5F1;
      position: relative; }
    .chat-orders__text a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: #0EA5F1;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/ }
    @media (hover: hover) {
      .chat-orders__text a:hover:after,
      .chat-orders__text a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }
    .chat-orders__text:not(:last-child) {
      margin-bottom: 128px; }
  .chat-orders__block {
    display: flex;
    gap: 36px;
    justify-content: center; }
  @media (max-width: 991px) {
    .chat-orders {
      padding: 80px 0px; }
      .chat-orders__block {
        flex-wrap: wrap;
        gap: 63px; } }
  @media (max-width: 575px) {
    .chat-orders {
      padding: 80px 0px 80px; }
      .chat-orders__title {
        margin-bottom: 16px; }
      .chat-orders__text:not(:last-child) {
        margin-bottom: 72px; } }
  @media (max-width: 479px) {
    .chat-orders__title br {
      display: block; }
    .chat-orders__text {
      font-size: 16px; }
      .chat-orders__text br {
        display: block; } }

.loginDflex {
  display: flex !important; }

#user-code {
  display: none; }
  #user-code .entrance__form > div {
    position: relative; }
  #user-code .entrance__form > div > label {
    display: none;
    font-size: 20px;
    color: #EB5757;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    left: 36px;
    gap: 106px; }
  #user-code .entrance__form > div > input:invalid {
    border: 2px solid #EB5757; }
    #user-code .entrance__form > div > input:invalid + label {
      display: flex; }
  #user-code form input::placeholder {
    color: #000;
    opacity: .3;
    font-size: 20px;
    font-weight: 400; }
  #user-code form a {
    position: relative;
    color: #0EA5F1;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    margin: 0 auto; }
  #user-code form a::before {
    display: block;
    position: absolute;
    width: 0;
    /*задаём длинну линии до наведения курсора*/
    bottom: 0;
    height: 2px;
    /*задаём ширину линии*/
    background-color: #0EA5F1;
    /*задаём цвет линии*/
    content: "";
    transition: width 0.3s ease-out;
    /*задаём время анимации*/ }
  @media (hover: hover) {
    #user-code form a:hover::before,
    #user-code form a:focus::before {
      width: 100%;
      /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }
  @media (max-width: 575px) {
    #user-code form a {
      font-size: 14px; }
    #user-code .entrance__form > div > label {
      font-size: 14px;
      left: 36px;
      gap: 78px; } }

#user-name .entrance__form > div {
  position: relative; }

#user-name .entrance__form > div > label {
  font-weight: 700;
  font-size: 20px;
  color: #000;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 36px; }

#user-name .entrance__form input {
  padding-left: 64px; }

@media (max-width: 575px) {
  #user-name .entrance__form > div > label {
    font-size: 14px;
    line-height: 171%; }
  #user-name .entrance__form input {
    padding-left: 58px; }
  #user-name .entrance__form input::placeholder {
    font-size: 14px; } }

#user-success {
  display: none; }

.entrance {
  padding: 160px 0px; }
  .entrance__body {
    background: #F6F8FA;
    border-radius: 48px;
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .entrance__title {
    margin-bottom: 24px; }
  .entrance__text {
    color: #728A96;
    text-align: center;
    max-width: 612px;
    margin-bottom: 36px;
    font-size: 20px; }
    .entrance__text span {
      color: #000; }
    .entrance__text a {
      position: relative;
      color: #0EA5F1; }
    .entrance__text a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: #0EA5F1;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/ }
    @media (hover: hover) {
      .entrance__text a:hover:after,
      .entrance__text a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }
  .entrance__form {
    display: flex;
    flex-direction: column;
    gap: 36px; }
    .entrance__form input {
      width: 396px;
      font-size: 20px;
      height: 72px;
      border: none;
      border-radius: 124px;
      padding: 21px 36px; }
  .entrance__tgs {
    max-width: 700px;
    max-height: 700px;
    display: flex;
    margin: 0 auto; }
  @media (max-height: 800px) {
    .entrance {
      padding: 120px; }
      .entrance__tgs {
        max-width: 600px;
        max-height: 550px; } }
  @media (max-width: 1600px) {
    .entrance {
      padding: 120px 0px 80px; }
      .entrance__body {
        padding: 50px 0px; }
      .entrance__title.title {
        font-size: 48px; } }
  @media (max-width: 991px) {
    .entrance__body {
      padding: 40px 10px; }
    .entrance__title.title {
      font-size: 42px; }
    .entrance__button.btn {
      height: 72px; } }
  @media (max-width: 767px) {
    .entrance__body {
      border-radius: 0; }
    .entrance__title.title {
      font-size: 36px; }
    .entrance__form {
      gap: 26px; }
      .entrance__form input {
        width: 280px;
        height: 52px;
        padding: 14px 36px;
        font-size: 16px; }
    .entrance__button.btn {
      height: 52px; }
    .entrance__text {
      margin-bottom: 24px; }
    .entrance__tgs {
      max-width: 303px;
      max-height: 303px; } }
  @media (max-width: 575px) {
    .entrance__body {
      padding: 0; }
    .entrance__title {
      margin-bottom: 16px; }
      .entrance__title.title {
        font-size: 32px; }
    .entrance__form input {
      width: 260px; }
    .entrance__text {
      font-size: 16px; } }
  @media (max-height: 620px) {
    .entrance {
      padding: 80px 0px 40px; } }
  @media (max-width: 374px) {
    .entrance {
      padding: 70px 0px 30px; }
      .entrance__title {
        margin-bottom: 12px; }
        .entrance__title.title {
          font-size: 24px; }
      .entrance__text {
        margin-bottom: 12px;
        font-size: 12px; }
      .entrance__form {
        gap: 20px; }
      .entrance__tgs {
        max-width: 280px;
        max-height: 280px; } }

@media (max-width: 767px) {
  .bg-wrapper {
    background: #F6F8FA; } }

.exchanges {
  padding: 80px 0px 160px; }
  .exchanges__body {
    background-color: #F6F8FA;
    border-radius: 48px;
    padding: 81px 72px 80px 108px;
    display: flex;
    column-gap: 106px; }
  .exchanges__left {
    text-align: start;
    flex-basis: 504px; }
  .exchanges__logo {
    margin-bottom: 19px; }
  .exchanges__title {
    margin-bottom: 24px;
    color: #000; }
  .exchanges__text {
    color: #728A96;
    font-size: 20px; }
    .exchanges__text:not(:last-child) {
      margin-bottom: 48px; }
  .exchanges__button {
    text-transform: inherit; }
  .exchanges__img {
    width: 120px;
    height: 120px;
    border-radius: 37px;
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1199px) {
    .exchanges__right {
      position: relative;
      flex-basis: 469px; }
    .exchanges__img {
      position: absolute; }
      .exchanges__img_1 {
        top: -0.8%;
        left: 2.98%; }
      .exchanges__img_2 {
        top: 24.17%;
        left: 36.87%; }
      .exchanges__img_3 {
        top: 10.18%;
        right: 0; }
      .exchanges__img_4 {
        top: 179px;
        left: 0; }
      .exchanges__img_5 {
        bottom: 8.76%;
        right: 18.74%; } }
  @media (max-width: 1199px) {
    .exchanges__right {
      display: flex;
      column-gap: 24px;
      flex-wrap: wrap;
      flex-basis: 50%; } }
  @media (max-width: 991px) {
    .exchanges {
      padding: 80px 0px 80px; }
      .exchanges__body {
        flex-direction: column-reverse;
        row-gap: 30px; }
      .exchanges__right {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        flex-basis: 50%;
        align-items: center;
        justify-content: center; }
      .exchanges__left {
        text-align: center;
        flex-basis: content;
        align-items: center; }
      .exchanges__button.btn {
        width: 303px; } }
  @media (max-width: 767px) {
    .exchanges {
      padding: 0;
      background-color: #F6F8FA; }
      .exchanges__body {
        padding: 40px 4px; } }
  @media (max-width: 575px) {
    .exchanges__body {
      row-gap: 32px; }
    .exchanges__img {
      width: 76.6px;
      height: 76.6px;
      border-radius: 37px;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15); }
    .exchanges__title {
      margin-bottom: 16px; }
    .exchanges__text {
      font-size: 16px; }
      .exchanges__text:not(:last-child) {
        margin-bottom: 23px; } }
  @media (max-width: 374px) {
    .exchanges__right {
      gap: 20px; }
    .exchanges__button.btn {
      width: 280px; } }

.faq {
  padding: 80px 0px 160px; }
  .faq__title:not(:last-child) {
    margin-bottom: 64px; }
  .faq__block {
    max-width: 828px;
    margin: 0 auto; }
    .faq__block .faq-item:not(:last-child) {
      margin-bottom: 24px; }
  @media (max-width: 575px) {
    .faq {
      padding: 80px 0px 80px; }
      .faq__title:not(:last-child) {
        margin-bottom: 23px; }
      .faq__block .faq-item:not(:last-child) {
        margin-bottom: 16px; } }

@supports (-webkit-line-clamp: 6) {
  .your-class {
    display: -webkit-box;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden; } }

.footer {
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);
  height: 132px;
  display: flex;
  align-items: center; }
  .footer__body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .footer__left {
    display: flex;
    column-gap: 36px;
    font-weight: 700;
    color: #FFF; }
    .footer__left span {
      opacity: 0.5; }
    .footer__left a {
      position: relative; }
    .footer__left a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: #FFF;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/ }
    @media (hover: hover) {
      .footer__left a:hover:after,
      .footer__left a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }
  .footer__right {
    display: flex;
    align-items: center; }
    .footer__right a {
      color: #0EA5F1;
      font-weight: 700;
      position: relative; }
      .footer__right a:first-child {
        margin-right: 37px; }
        .footer__right a:first-child::after {
          display: block;
          position: absolute;
          left: 0;
          /*изменить на right:0;, чтобы изменить направление подчёркивания */
          width: 0;
          /*задаём длинну линии до наведения курсора*/
          height: 2px;
          /*задаём ширину линии*/
          background-color: #0EA5F1;
          /*задаём цвет линии*/
          content: "";
          transition: width 0.3s ease-out;
          /*задаём время анимации*/ }
        @media (hover: hover) {
          .footer__right a:first-child:hover:after, .footer__right a:first-child:focus:after {
            width: 100%;
            /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }
  .footer__img {
    display: inline-flex;
    max-width: 40px; }
    .footer__img:not(:last-child) {
      margin-right: 16px; }
  @media (max-width: 1199px) {
    .footer__body {
      flex-wrap: wrap-reverse;
      gap: 48px;
      align-items: center;
      justify-content: center; } }
  @media (max-width: 991px) {
    .footer {
      height: 241px; }
      .footer__right {
        position: relative; }
        .footer__right::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          left: 0;
          bottom: -24px;
          background: #FFF;
          opacity: 0.1; } }
  @media (max-width: 767px) {
    .footer {
      font-size: 16px; }
      .footer__left {
        flex-wrap: wrap-reverse;
        text-align: center;
        justify-content: center;
        gap: 8px; }
        .footer__left a {
          flex-basis: 100%; } }
  @media (max-width: 575px) {
    .footer__body {
      justify-content: start; }
    .footer__right {
      flex-basis: 100%; }
      .footer__right a:first-child {
        flex-grow: 1; }
    .footer__left {
      text-align: start;
      justify-content: start; } }

#logo-black {
  display: none; }

.header__color.scrolled #logo-white {
  display: block; }

.header__color.scrolled .menu__icon span {
  background-color: #FFF; }

.header__color.scrolled .menu__icon::before, .header__color.scrolled .menu__icon::after {
  background-color: #FFF; }

.header__color.scrolled #logo-black {
  display: none; }

.header__color.scrolled .menu__list {
  color: #FFF; }

.header__color.scrolled .menu__connect::before {
  background-color: #FFF;
  opacity: .3; }

.header__color .menu__list {
  color: #000; }
  .header__color .menu__list a::after {
    background-color: #000; }

.header__color .menu__connect::before {
  background-color: #000; }

.header__color #logo-black {
  display: block; }

.header__color #logo-white {
  display: none; }

@media (max-width: 991px) {
  .header__color.active #logo-black {
    display: none; }
  .header__color.active #logo-white {
    display: block; }
  .header__color .active .menu__list {
    color: #FFF; }
  .header__color .menu__icon::after, .header__color .menu__icon::before,
  .header__color .menu__icon span {
    background-color: #000; }
  .header__color .menu__icon.active::after, .header__color .menu__icon.active::before,
  .header__color .menu__icon.active span {
    background-color: #FFF; } }

.scrolled {
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);
  backdrop-filter: blur(10px); }

.header {
  color: #FFF;
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2; }
  .header__body {
    display: flex;
    justify-content: space-between;
    padding: 22px 0px 16px; }
  @media (max-width: 991px) {
    .header {
      height: 60px;
      align-items: center;
      display: flex; }
      .header__body {
        align-items: center;
        padding: 0px 16px 0px 4px; } }
  @media (max-width: 575px) {
    .header__logo {
      width: 113.82px; } }

.menu__body {
  display: flex;
  column-gap: 151px;
  padding-top: 5px; }

.menu__list {
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: 37px; }
  .menu__list a {
    position: relative; }
  .menu__list a::after {
    display: block;
    position: absolute;
    left: 0;
    /*изменить на right:0;, чтобы изменить направление подчёркивания */
    width: 0;
    /*задаём длинну линии до наведения курсора*/
    height: 2px;
    /*задаём ширину линии*/
    background-color: #FFF;
    /*задаём цвет линии*/
    content: "";
    transition: width 0.3s ease-out;
    /*задаём время анимации*/ }
  @media (hover: hover) {
    .menu__list a:hover:after,
    .menu__list a:focus:after {
      width: 100%;
      /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }

.menu__login {
  display: flex;
  color: #0EA5F1;
  column-gap: 54px;
  transition: text-decoration .3s; }
  .menu__login a {
    position: relative; }
  .menu__login a::after {
    display: block;
    position: absolute;
    left: 0;
    /*изменить на right:0;, чтобы изменить направление подчёркивания */
    width: 0;
    /*задаём длинну линии до наведения курсора*/
    height: 2px;
    /*задаём ширину линии*/
    background-color: #0EA5F1;
    /*задаём цвет линии*/
    content: "";
    transition: width 0.3s ease-out;
    /*задаём время анимации*/ }
  @media (hover: hover) {
    .menu__login a:hover:after,
    .menu__login a:focus:after {
      width: 100%;
      /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/ } }

@media (max-width: 1199px) {
  .menu__body {
    column-gap: 100px; }
  .menu__login {
    column-gap: 25px; }
  .menu__list {
    column-gap: 20px; } }

@media (min-width: 991px) {
  .menu__connect {
    position: relative; }
    .menu__connect::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -14.5px;
      height: 14px;
      width: 2px;
      background-color: #FFF;
      opacity: .3; }
  .menu__support {
    display: none; } }

@media (min-width: 1199px) {
  .menu__connect::before {
    right: -27px; }
  .menu__support {
    display: none; } }

@media (max-width: 991px) {
  .header__logo {
    z-index: 5; }
  .menu__icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 12px;
    height: 19px;
    cursor: pointer; }
    .menu__icon span, .menu__icon::after, .menu__icon::before {
      left: 0;
      position: absolute;
      height: 3px;
      width: 100%;
      transition: all .3s;
      background-color: #FFF; }
    .menu__icon::after, .menu__icon::before {
      content: ''; }
    .menu__icon::after {
      top: 0;
      width: 200%; }
    .menu__icon::before {
      bottom: 0; }
    .menu__icon span {
      top: 50%;
      transform: scale(1) translate(0px, -50%); }
    .menu__icon.active span {
      transform: scale(0) translate(0px, -50%); }
    .menu__icon.active:before {
      width: 200%;
      bottom: 50%;
      transform: rotate(45deg) translate(0px, 50%); }
    .menu__icon.active::after {
      top: 49%;
      transform: rotate(-45deg) translate(0px, -50%); }
  .menu__body {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 120px 0px 36px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);
    backdrop-filter: blur(10px);
    transition: left .3s;
    z-index: 4;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: auto; }
    .menu__body.active {
      left: 0; }
  .menu__list {
    flex-direction: column;
    color: #FFF;
    row-gap: 24px;
    text-align: center;
    font-size: 20px; }
  .menu__login {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 24px; }
    .menu__login .btn {
      font-size: 16px;
      width: 303px; }
  .menu__support {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    padding: 36px 0px;
    margin-top: 16px;
    position: relative; }
    .menu__support > a {
      color: #0EA5F1; }
    .menu__support::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 48px;
      height: 1px;
      background-color: #FFF;
      opacity: .3; }
  .menu__img {
    display: flex;
    gap: 24px; } }

@media (max-height: 620px) and (max-width: 479px) {
  .menu__body {
    padding: 60px 0px 26px; }
  .menu__list {
    row-gap: 20px; }
  .menu__support {
    row-gap: 20px;
    padding: 24px 0px;
    margin-top: 4px; }
  .menu__login {
    row-gap: 20px; } }

.monitoring {
  padding: 128px 0px 130px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%); }
  .monitoring__body {
    display: flex;
    gap: 36px;
    color: #FFF; }
  .monitoring__left {
    padding-top: 113px; }
  .monitoring__title {
    font-size: 64px;
    line-height: 112%;
    font-weight: 800;
    margin-bottom: 36px; }
    .monitoring__title span {
      color: #0EA5F1; }
  .monitoring__text {
    font-size: 20px;
    opacity: .5;
    margin-bottom: 48px; }
  .monitoring__button {
    display: flex;
    column-gap: 37px; }
    .monitoring__button button {
      text-transform: inherit; }
  .monitoring__more {
    background-color: #303035; }
    @media (hover: hover) {
      .monitoring__more:hover {
        background-color: #fff;
        color: #303035; } }
    .monitoring__more:active {
      background-color: #fff;
      color: #303035; }
  .monitoring__right {
    background-color: #333336;
    border-radius: 16px;
    flex-basis: 31.43%;
    flex-shrink: 0;
    max-height: 644px;
    overflow: auto;
    position: relative;
    cursor: pointer;
    -webkit-overflow-scrolling: none; }
    .monitoring__right::-webkit-scrollbar {
      display: none; }
  .monitoring__header {
    background-color: #29292E;
    padding: 15px 12px;
    font-size: 14px;
    line-height: 129%;
    font-weight: 800;
    display: flex;
    position: sticky;
    justify-content: center;
    top: 0px;
    max-width: 100%;
    z-index: 1; }
  .monitoring__circle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #FFBB2E;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    align-self: center;
    left: 32px; }
    .monitoring__circle::after, .monitoring__circle::before {
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.12); }
    .monitoring__circle::after {
      background-color: #38C149;
      right: -21px; }
    .monitoring__circle::before {
      left: -21px;
      background-color: #FF5E57; }
  .monitoring__list {
    padding: 36px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: auto !important; }
  @media (min-height: 810px) and (min-width: 1199px) {
    .monitoring {
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100); } }
  @media (max-width: 1199px) {
    .monitoring__title {
      font-size: 54px; }
    .monitoring__right {
      min-width: 327px; } }
  @media (max-width: 991px) {
    .monitoring {
      padding: 120px 0px 40px; }
      .monitoring__body {
        flex-wrap: wrap;
        justify-content: center; }
      .monitoring__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0; }
      .monitoring__right {
        flex-basis: 100%;
        max-width: 500px;
        min-width: 327px; } }
  @media (max-width: 575px) {
    .monitoring__title {
      font-size: 34px;
      line-height: 129%;
      margin-bottom: 16px; }
    .monitoring__text {
      font-size: 16px;
      margin-bottom: 24px; }
    .monitoring__button {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 16px; }
      .monitoring__button * {
        width: 303px; }
    .monitoring__right {
      flex-basis: 100%;
      max-width: 327px;
      min-width: 327px;
      overflow: hidden; }
      .monitoring__right .swiper-pagination {
        bottom: 20px !important; }
    .monitoring__block {
      max-width: 327px; }
    .monitoring__list {
      padding: 0;
      margin: 24px 12px 60px;
      position: relative;
      -webkit-box-orient: horizontal;
      height: 128px;
      flex-direction: row; } }

.notifications {
  padding: 80px 0px; }
  .notifications__body {
    border-radius: 48px;
    display: flex;
    background-color: #F6F8FA;
    padding: 80px 108px;
    column-gap: 11px; }
  .notifications__block {
    flex-basis: 61.1%; }
  .notifications__title {
    margin-bottom: 24px; }
  .notifications__sp {
    color: #0EA5F1;
    font-size: 24px;
    line-height: 133%;
    font-weight: 800;
    display: flex;
    margin-bottom: 3px; }
  .notifications__text {
    color: #728A96;
    max-width: 555px;
    font-size: 20px; }
    .notifications__text span {
      font-size: 2px; }
    .notifications__text br:nth-child(1) {
      display: none; }
    .notifications__text:not(:last-child) {
      margin-bottom: 47px; }
  .notifications__tgs {
    max-width: 396px;
    max-height: 396px;
    display: flex; }
  @media (max-width: 1199px) {
    .notifications__body {
      padding: 80px 0px;
      flex-wrap: wrap-reverse;
      align-items: center;
      justify-content: center;
      row-gap: 30px; }
    .notifications__block {
      flex-basis: content;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; } }
  @media (max-width: 767px) {
    .notifications {
      background-color: #F6F8FA;
      padding: 0; } }
  @media (max-width: 575px) {
    .notifications__tgs {
      max-width: 247px;
      max-height: 247px; }
    .notifications__title {
      margin-bottom: 16px; }
    .notifications span {
      font-size: 20px;
      line-height: 150%; }
    .notifications__body {
      row-gap: 24px;
      padding: 33px 0px 40px; }
    .notifications__text {
      font-size: 16px; }
      .notifications__text br {
        display: block; }
        .notifications__text br:nth-child(1) {
          display: block; }
      .notifications__text:not(:last-child) {
        margin-bottom: 36px; }
    .notifications__button.btn {
      width: 303px; } }
  @media (max-width: 374px) {
    .notifications__button.btn {
      width: 280px; } }

.subscription {
  padding: 160px 0px 80px; }
  .subscription__title {
    margin-bottom: 24px; }
  .subscription__text {
    max-width: 944px;
    margin: 0 auto;
    font-size: 20px;
    color: #728A96;
    margin-bottom: 112px;
    text-align: center; }
  .subscription__block {
    display: flex;
    column-gap: 36px;
    justify-content: center; }
  .subscription__button {
    color: #000;
    background-color: #FFF; }
    @media (hover: hover) {
      .subscription__button:hover {
        color: #FFF;
        background-color: yellowgreen; } }
  @media (max-width: 991px) {
    .subscription {
      padding: 80px 0px 80px; }
      .subscription__text {
        margin-bottom: 80px; }
      .subscription__block {
        column-gap: 36px;
        row-gap: 66px;
        flex-wrap: wrap; } }
  @media (max-width: 575px) {
    .subscription__block {
      row-gap: 53px; }
    .subscription__title {
      margin-bottom: 16px; }
    .subscription__text {
      font-size: 16px;
      margin-bottom: 60px; } }

.page-title-lg {
  padding: 130px 0 130px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0, #232328 100%);
  text-align: center;
  color: #fff; }
  .page-title-lg h1 {
    font-size: 52px; }

.section {
  padding: 100px 0px;
  color: #57667E; }
  .section strong {
    font-size: 15px;
    line-height: 1.6; }
  .section p {
    margin-bottom: 1rem;
    line-height: 1.6; }

.wholesome {
  padding: 160px 0px 162px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);
  color: #FFF; }
  .wholesome__title {
    margin-bottom: 64px; }
  .wholesome__body {
    display: flex;
    justify-content: center;
    column-gap: 4px; }
    .wholesome__body #wholesome-title-tablet {
      display: none; }
    .wholesome__body ul {
      padding: 0;
      list-style: none; }
  .wholesome__list {
    font-size: 20px;
    flex-basis: 504px;
    margin-top: 49px; }
    .wholesome__list li {
      align-items: center;
      display: flex; }
      .wholesome__list li:not(:last-child) {
        margin-bottom: 30px; }
      .wholesome__list li img {
        max-width: 30px;
        margin-right: 16px; }
  .wholesome__tgs {
    max-width: 418px;
    max-height: 418px; }
  @media (max-width: 991px) {
    .wholesome {
      padding: 80px 0px; }
      .wholesome #wholesome-title-desktop {
        display: none; }
      .wholesome__body {
        flex-wrap: wrap-reverse; }
        .wholesome__body #wholesome-title-tablet {
          display: block; }
      .wholesome__title {
        margin-top: 24px;
        margin-bottom: 34px; }
      .wholesome__list {
        margin-top: 0px; } }
  @media (max-width: 575px) {
    .wholesome {
      padding: 40px 0px; }
      .wholesome__tgs {
        max-width: 247px;
        max-height: 247px; }
      .wholesome__title {
        margin-bottom: 24px; }
      .wholesome__list {
        padding-left: 6px !important; }
        .wholesome__list li {
          font-size: 16px; }
          .wholesome__list li:not(:last-child) {
            margin-bottom: 12px; }
          .wholesome__list li img {
            max-width: 20px;
            margin-right: 14px; } }
  @media (max-width: 374px) {
    .wholesome__list {
      padding-left: 0px; }
      .wholesome__list li:not(:last-child) {
        margin-bottom: 10px; } }

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
