 @supports (-webkit-line-clamp: 6) {
 	.your-class {
 			display: -webkit-box;
 			white-space: normal;
 			text-overflow: ellipsis;
 			-webkit-box-orient: vertical;//
 			-webkit-line-clamp: 6;
 			overflow: hidden;
 	}
 }

.footer {
	background: $bg-gradient;
	height: 132px;
	display: flex;
	align-items: center;
	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__left {
		display: flex;
		column-gap: 36px;
		font-weight: 700;
		color: $white;
		& span {
			opacity: 0.5;
		}
		& a {
      position: relative;
    }
    & a::after {
      display: block;
      position: absolute;
      left: 0; /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;/*задаём длинну линии до наведения курсора*/
      height: 2px; /*задаём ширину линии*/
      background-color: $white; /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out; /*задаём время анимации*/
    }
    @media(hover:hover) {
        a:hover:after,
        a:focus:after {
          width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
        }
    }
	}

	&__right {
		display: flex;
		align-items: center;
		& a {
			color: $blue;
			font-weight: 700;
			position: relative;
			&:first-child {
				margin-right: 37px;
				&::after {
					display: block;
					position: absolute;
					left: 0; /*изменить на right:0;, чтобы изменить направление подчёркивания */
					width: 0;/*задаём длинну линии до наведения курсора*/
					height: 2px; /*задаём ширину линии*/
					background-color: $blue; /*задаём цвет линии*/
					content: "";
					transition: width 0.3s ease-out; /*задаём время анимации*/
				}
				@media(hover:hover) {
						&:hover:after,
						&:focus:after {
							width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
						}
				}
			}
			
		}
    
	}
	&__img {
		display: inline-flex;
		max-width: 40px;
		&:not(:last-child) {
			margin-right: 16px;
		}
	}
	@media (max-width: $desktop) {
		&__body {
			flex-wrap: wrap-reverse;
			gap: 48px;
			align-items: center;
			justify-content: center;
		}
	}
	@media (max-width: $tablet) {
		height: 241px;
		&__right {
			position: relative;
			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				left: 0;
				bottom: -24px;
				background: $white;
				opacity: 0.1;
			}
		}
	}
	@media (max-width: $mobile-big) {
		font-size: 16px;
		&__left {
			flex-wrap: wrap-reverse;
			text-align: center;
			justify-content: center;
			gap: 8px;
			& a {
				flex-basis: 100%;
			}
		}
	}
	@media (max-width: $mobile) {
		&__body {
			justify-content: start;
			
		}
		&__right {
			flex-basis: 100%;
			& a:first-child {
				flex-grow: 1;
			}
		}
		&__left {
			text-align: start;
			justify-content: start;
		}
	}
}
