// Widths   420 479 575 640 767 991 1199 1400 
$mobile-sm:     479px;
$mobile:        575px;
$mobile-big:    767px;
$tablet:        991px;
$desktop:       1199px;

$grid-spacing: 30px;
$grid-spacing-sm: 20px;

$base-img: '../img/';

$font-size: 16px;
$line-height: 1.5;
// Fonts
$default-font: 'Mulish', sans-serif;//400/700/800
$additional-font: 'Montserrat', sans-serif;//400/700/800

// Colors
$text-color: #000;
$white: #FFF;
$blue: #0EA5F1;
$lite-black: #303035;
$color-error: #E60000;
$bg-gradient: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);