.wholesome {
  padding: 160px 0px 162px;
  background: $bg-gradient;
  color: $white;

  &__title {
    margin-bottom: 64px;
  }

  &__body {
    display: flex;
    justify-content: center;
    column-gap: 4px;

    #wholesome-title-tablet {
      display: none;
    }

    & ul {
      padding: 0;
      list-style: none;
    }
  }

  &__list {
    font-size: 20px;
    flex-basis: 504px;
    margin-top: 49px;

    & li {
      align-items: center;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      & img {
        max-width: 30px;
        margin-right: 16px;
      }

    }
  }

  &__tgs {
    max-width: 418px;
    max-height: 418px;
  }

  @media (max-width: $tablet) {
    #wholesome-title-desktop {
      display: none;
    }

    padding: 80px 0px;

    &__body {
      flex-wrap: wrap-reverse;

      #wholesome-title-tablet {
        display: block;
      }
    }

    &__title {
      margin-top: 24px;
      margin-bottom: 34px;
    }

    &__list {
      margin-top: 0px;
    }
  }

  @media (max-width: $mobile) {
    padding: 40px 0px;

    &__tgs {
      max-width: 247px;
      max-height: 247px;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__list {
      padding-left: 6px !important;

      & li {
        font-size: 16px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        & img {
          max-width: 20px;
          margin-right: 14px;
        }

      }
    }
  }

  @media (max-width: 374px) {
    &__list {
      padding-left: 0px;

      & li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}