.loginDflex {
  display: flex !important;
}


// Блок ввода кода 
#user-code {

  display: none;

  & .entrance__form>div {
    position: relative;
  }

  // Стили ВАЛИДАЦИИ
  & .entrance__form>div>label {
    display: none;
    font-size: 20px;
    color: #EB5757;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    left: 36px;
    gap: 106px;
  }

  & .entrance__form>div>input:invalid {
    border: 2px solid #EB5757;

    &+label {
      display: flex;
    }
  }

  & form {
    & input::placeholder {
      color: $text-color;
      opacity: .3;
      font-size: 20px;
      font-weight: 400;
    }

    & a {
      position: relative;
      color: $blue;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      margin: 0 auto;
    }

    & a::before {
      display: block;
      position: absolute;
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      bottom: 0;
      height: 2px;
      /*задаём ширину линии*/
      background-color: $blue;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/
    }

    @media(hover:hover) {

      a:hover::before,
      a:focus::before {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
      }
    }
  }

  @media (max-width: $mobile) {
    & form a {
      font-size: 14px;
    }

    // Стили ВАЛИДАЦИИ
    & .entrance__form>div>label {
      font-size: 14px;
      left: 36px;
      gap: 78px;
    }
  }
}

// Блок ввода логина
#user-name {

  // display: none;

  & .entrance__form>div {
    position: relative;

  }

  & .entrance__form>div>label {
    font-weight: 700;
    font-size: 20px;
    color: $text-color;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 36px;
  }

  & .entrance__form input {
    padding-left: 64px;
  }

  @media (max-width: $mobile) {
    & .entrance__form>div>label {
      font-size: 14px;
      line-height: 171%;
    }

    & .entrance__form input {
      padding-left: 58px;
    }

    & .entrance__form input::placeholder {
      font-size: 14px;
    }
  }
}

// Блок успешного входа 
#user-success {
  display: none;
}

//Всплывающий текс при ошибке ввода
// .fn_name_error {
//   display: none;
// }
// Общии стили
.entrance {
  padding: 160px 0px;

  &__body {
    background: #F6F8FA;
    border-radius: 48px;
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    color: #728A96;
    text-align: center;
    max-width: 612px;
    margin-bottom: 36px;
    font-size: 20px;

    & span {
      color: $text-color;
    }

    & a {
      position: relative;
      color: $blue;
    }

    & a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: $blue;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/
    }

    @media(hover:hover) {

      a:hover:after,
      a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 36px;

    & input {
      width: 396px;
      font-size: 20px;
      height: 72px;
      border: none;
      border-radius: 124px;
      padding: 21px 36px;
    }
  }

  &__tgs {
    max-width: 700px;
    max-height: 700px;
    display: flex;
    margin: 0 auto;
  }

  @media (max-height: 800px) {
    padding: 120px;

    &__tgs {
      max-width: 600px;
      max-height: 550px;
    }
  }

  @media (max-width: 1600px) {
    padding: 120px 0px 80px;

    &__body {
      padding: 50px 0px;
    }

    &__title {
      &.title {
        font-size: 48px;
      }
    }
  }

  @media (max-width: $tablet) {
    &__body {
      padding: 40px 10px;
    }

    &__title {
      &.title {
        font-size: 42px;
      }
    }

    &__button.btn {
      height: 72px;
    }
  }

  @media (max-width: $mobile-big) {

    &__body {
      border-radius: 0;
    }

    &__title {
      &.title {
        font-size: 36px;
      }
    }

    &__form {
      gap: 26px;

      & input {
        width: 280px;
        height: 52px;
        padding: 14px 36px;
        font-size: 16px;
      }
    }

    &__button.btn {
      height: 52px;
    }

    &__text {
      margin-bottom: 24px;
    }

    &__tgs {
      max-width: 303px;
      max-height: 303px;
    }
  }

  @media (max-width: $mobile) {
    &__body {
      padding: 0;
    }

    &__title {
      margin-bottom: 16px;

      &.title {
        font-size: 32px;
      }
    }

    &__form {
      & input {
        width: 260px;
      }
    }

    &__text {
      font-size: 16px;
    }


  }

  @media (max-height: 620px) {
    padding: 80px 0px 40px;
  }

  @media (max-width: 374px) {
    padding: 70px 0px 30px;

    &__title {
      margin-bottom: 12px;

      &.title {
        font-size: 24px;
      }
    }

    &__text {
      margin-bottom: 12px;
      font-size: 12px;
    }

    &__form {
      gap: 20px;
    }

    &__tgs {
      max-width: 280px;
      max-height: 280px;
    }
  }
}

@media (max-width: $mobile-big) {
  .bg-wrapper {
    background: #F6F8FA;
  }
}