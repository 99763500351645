.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.apple-fix {
  width: 100%;
  position: fixed;
}

.no-scroll {
  overflow: hidden;
}

.clearfix {
  &::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include heightVH(100, min); //ios
  width: 100%;
  overflow-x: hidden;
  position: relative;

  &.sticky {
    overflow: unset;
  }

  .header {
    flex: 0 0 auto;
  }

  main {
    position: relative;
    flex: 1 0 auto;
  }

  .footer {
    flex: 0 0 auto;
  }
}

.table {
  display: table;
  width: 100%;
  border: 1px solid hsl(0, 0%, 70%);
  border-collapse: collapse;

  td,
  th {
    padding: 5px 10px;
    vertical-align: top;
    border-bottom: 1px solid hsl(0, 0%, 70%);

    &:not(:first-child) {
      border-left: 1px solid hsl(0, 0%, 70%);
    }
  }

  th {
    text-align: inherit;
  }
}

.is-default-hidden {
  display: none !important;
}

// @supports (-webkit-line-clamp: 6) {
// 	.your-class {
// 			display: -webkit-box;
// 			white-space: normal;
// 			text-overflow: ellipsis;
// 			-webkit-box-orient: vertical;//
// 			-webkit-line-clamp: 6;
// 			overflow: hidden;
// 	}
// }