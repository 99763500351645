.subscription {
  padding: 160px 0px 80px;
  &__title {
    margin-bottom: 24px;
  }
  &__text {
    max-width: 944px;
    margin: 0 auto;
    font-size: 20px;
    color: #728A96;
    margin-bottom: 112px;
    text-align: center;
  }
  &__block {
    display: flex;
    column-gap: 36px;
    justify-content: center;
  }
  &__button {
    color: $text-color;
    background-color: $white;
    @media (hover: hover) {
      &:hover {
        color: $white;
        background-color: yellowgreen;
      }
    }
  }
  @media (max-width: $tablet) {
    padding: 80px 0px 80px;
    &__text {
      margin-bottom: 80px;
    }
    &__block {
      column-gap: 36px;
      row-gap: 66px;
      flex-wrap: wrap;
    }
  }
  @media (max-width: $mobile) {
    &__block {
      row-gap: 53px;
    }
    &__title {
      margin-bottom: 16px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 60px;
    }
  }
}