.card-chat {
  background-color: #F6F8FA;
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-basis: 324px;

  &__body {
    padding: 0px 36px 64px;
    font-size: 24px;
    font-weight: 800;
    line-height: 133%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tgs {
    max-width: 212px;
    max-height: 212px;
    margin-top: -65px;
  }

  &__text {
    padding-top: 24px;
  }

  @media (max-width: $mobile) {
    &__body {
      padding: 0px 36px 36px;
      font-size: 20px;
      line-height: 150%;
    }

    &__tgs {
      max-width: 143px;
      max-height: 144px;
      margin-top: -47px;
    }

    &__text {
      padding-top: 16px;
    }
  }
}