.title {
  font-size: 56px;
  font-weight: 800;
  line-height: 114%;
  &__center {
    text-align: center;
    max-width: 828px;
    margin: 0 auto;
  }
  @media (max-width: $mobile-big) {
    font-size: 46px;
  }
  @media (max-width: $mobile) {
    font-size: 36px;
  }
  @media (max-width: $mobile-sm) {
    font-size: 32px;
    line-height: 125%;
    text-align: center;
  }
  @media (max-width: 374px) {
    font-size: 28px;
  }
}