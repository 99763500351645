.monitoring {
  padding: 128px 0px 130px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, 0.95) 0%, #232328 100%);
  &__body {
    display: flex;
    gap: 36px;
    color: $white;
  }
  &__left {
    padding-top: 113px;
  }
  &__title {
    font-size: 64px;
    line-height: 112%;
    font-weight: 800;
    margin-bottom: 36px;
    & span {
      color: $blue;
    }
  }
  &__text {
    font-size: 20px;
    opacity: .5;
    margin-bottom: 48px;
  }
  &__button {
    display: flex;
    column-gap: 37px;
    & button {
      text-transform: inherit;
    }
    
  }
  &__more {
    background-color: $lite-black;
    @media (hover: hover) {
      &:hover {
        background-color: #fff;
        color: $lite-black;
      }
    }
    &:active {
      background-color: #fff;
      color: $lite-black;
    }
  
  }
  &__right {
    background-color: #333336;
    // background-color: #fff;
    border-radius: 16px;
    flex-basis: 31.43%;
    flex-shrink: 0;
    max-height: 644px;
    overflow: auto;
    position: relative;
    cursor: pointer;
    &::-webkit-scrollbar {
      display: none;
    }
    -webkit-overflow-scrolling: none;
  }
  &__header {
    background-color: #29292E;
    padding: 15px 12px;
    font-size: 14px;
    line-height: 129%;
    font-weight: 800;
    display: flex;
    position: sticky;
    justify-content: center;
    top: 0px;
    max-width: 100%;
    z-index: 1;
  }
  &__circle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #FFBB2E;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    align-self: center;
    left: 32px;
    &::after,
    &::before {
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.12);
    }
    &::after {
      background-color: #38C149;
      right: -21px;
    }
    &::before {
      left: -21px;
      background-color: #FF5E57;
    }
  }
  &__list {
      padding: 36px 24px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: auto !important;
  }
  
  @media (min-height: 810px) and (min-width: 1199px) {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100); 
  }
  @media (max-width: $desktop) {
    &__title {
      font-size: 54px;
    }
    &__right {
      min-width: 327px;
    }
  }
  @media (max-width: $tablet) {
    padding: 120px 0px 40px;
    &__body {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0;
    }
    &__right {
      flex-basis: 100%;
      max-width: 500px;
      min-width: 327px;
    }
  }
  @media (max-width: $mobile) {
    &__title {
      font-size: 34px;
      line-height: 129%;
      margin-bottom: 16px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 24px;
    }
    &__button {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 16px;
      & * {
        width: 303px;
      }
    }
    &__right {
      flex-basis: 100%;
      max-width: 327px;
      min-width: 327px;
      overflow: hidden;
      & .swiper-pagination {
        bottom: 20px!important; 
      }
    }
    &__block{
      max-width: 327px;
    }
    &__list {
      padding: 0;
      margin: 24px 12px 60px;
      position: relative;
      -webkit-box-orient: horizontal;
      height: 128px;
      flex-direction: row;
    }
  }
}
