.chat-orders {
  padding: 160px 0px 80px;
  &__title {
    color: $text-color;
    margin-bottom: 24px;
    & br {
      display: none;
    }
  }
  &__text {
    font-size: 20px;
    color: #728A96;
    text-align: center;
    margin: 0 auto;
    max-width: 612px;
    & br {
      display: none;
    }
    & a {
      color: $blue;
      position: relative;
    }
    & a::after {
      display: block;
      position: absolute;
      left: 0; /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;/*задаём длинну линии до наведения курсора*/
      height: 2px; /*задаём ширину линии*/
      background-color: $blue; /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out; /*задаём время анимации*/
    }
    @media(hover:hover) {
        a:hover:after,
        a:focus:after {
          width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
        }
    }
    &:not(:last-child){
      margin-bottom: 128px;
    }
  }
  &__block {
    display: flex;
    gap: 36px;
    justify-content: center;
  }
  @media (max-width: $tablet) {
    padding: 80px 0px;
    &__block {
      flex-wrap: wrap;
      gap: 63px;
    }
  }
  @media (max-width: $mobile) {
    padding: 80px 0px 80px;
    &__title {
      margin-bottom: 16px;
    }
    &__text {
      &:not(:last-child){
        margin-bottom: 72px;
      }
    }
    
  }
  @media (max-width: $mobile-sm) {
    &__title {
      & br {
        display: block;
      }
    }
    &__text {
      font-size: 16px;
      & br {
        display: block;
      }
      
    }
  }
}