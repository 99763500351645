.page-title-lg {
  padding: 130px 0 130px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(35, 35, 40, .95) 0, #232328 100%);
  text-align: center;
  color: #fff;

  & h1 {
    font-size: 52px;
  }

}

.section {
  padding: 100px 0px;
  color: #57667E;

  & strong {
    font-size: 15px;
    line-height: 1.6;
  }

  & p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
}