.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 20px;
  height: 72px;
  border: none;
  border-radius: 124px;
  cursor: pointer;
  color: #fff;
  background-color: $blue;
  margin: 0;
  padding: 0 36px;
  z-index: 1;
  transition: .25s linear;
  transition-property: color, background-color, border-color;
  font-weight: 700;
  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      color: $blue;
    }
  }
  &:active {
    background-color: #fff;
    color: $blue;
  }
  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
  &--secondary {
    color: cornflowerblue;
    background-color: transparent;
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: cornflowerblue;
      }
    }
  }
}

.btn-inline {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  svg {
    fill: cornflowerblue;
    margin-right: 12px;
  }
}
@media (max-width: $tablet) {
  .btn {
    height: 52px;
  }
}
@media (max-width: $mobile) {
  .btn {
    font-size: 14px;
  }
}