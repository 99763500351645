.subscription-card {
  background-color: #F6F8FA;
  border-radius: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 324px;
  padding-bottom: 80px;
  &__img {
    max-width: 120px;
    max-height: 120px;
    box-shadow: 0 20px 35px rgba(0, 0, 0, .1);
    border-radius: 37px;
    margin-top: -46px;
    margin-bottom: 36px;
  }
  &__link {
    color: $blue;
    font-size: 24px;
    font-weight: 800;
    line-height: 133%;
    margin-bottom: 36px;
  }
  &__sum {
    font-size: 56px;
    line-height: 116%;
    font-weight: 800;
  }
  &__time {
    font-size: 20px;
    color: #728A96;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
  &__button {
    text-transform: inherit;
  }
  @media (max-width: $mobile-big) {
    padding-bottom: 60px;
    &__sum {
      font-size: 36px;
    }
    &__time {
      &:not(:last-child) {
        margin-bottom: 38px;
      }
    }
  }
  @media (max-width: $mobile) {
    padding-bottom: 36px;
    &__img {
      max-width: 96px;
      max-height: 96px;
      box-shadow: 0 15px 20px rgba(0, 0, 0, .15);
      margin-top: -36px;
      margin-bottom: 19px;
    }
    &__link {
      font-size: 20px;
      margin-bottom: 26px;
    }
    &__sum {
      font-size: 32px;
      line-height: 125%;
    }
    &__time {
      font-size: 16px;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &__button {
      width: 252px;
    }
  }
}