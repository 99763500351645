.message {
  background: #38383C;
  border-radius: 16px;

  &__body {
    padding: 16px;
    display: flex;
    column-gap: 12px;
    font-size: 14px;
    line-height: 129%;
  }

  &__logo {
    width: 36px;
    height: 36px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    opacity: 0.5;
    padding-bottom: 2px;
  }

  &__title {
    font-weight: 800;
    padding-bottom: 2px;
  }

  &__text {
    margin-bottom: 12px;
  }

  &__sum {
    font-weight: 800;
    color: $blue;
  }

  @media (max-width: $mobile) {
    &__top {
      font-size: 12px;
    }

    &__title {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &__text {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__sum {
      font-size: 12px;
    }
  }
}

