.faq-item {
  background-color: #F6F8FA;
  border-radius: 48px;
  padding: 36px 48px;
  &__title {
    font-size: 24px;
    line-height: 133%;
    font-weight: 800;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__text {
    color: #728A96;
    font-size: 20px;
  }
  @media (max-width: $mobile) {
    padding: 36px 28px;
    &__title {
      font-size: 16px;
      line-height: 150%;
    }
    &__text {
      font-size: 16px;
    }
  }
}