//--Смена логотипа Черный-Белый
#logo-black {
  display: none;
}

//--Доп класс на странице ВХОД
.header__color {
  &.scrolled {
    #logo-white {
      display: block;
    }

    & .menu__icon {
      & span {
        background-color: $white;
      }

      &::before,
      &::after {
        background-color: $white;
      }

    }

    #logo-black {
      display: none;
    }

    & .menu__list {
      color: $white;
    }

    & .menu__connect::before {
      background-color: $white;
      opacity: .3;
    }
  }

  & .menu__list {
    color: $text-color;

    & a::after {
      background-color: $text-color
        /*задаём цвет линии*/
    }
  }

  & .menu__connect::before {
    background-color: $text-color;
  }

  & #logo-black {
    display: block;
  }

  & #logo-white {
    display: none;
  }
}

//--Стилизация menu__icon + list +logo на странице ВХОД
@media (max-width: $tablet) {
  .header__color.active {
    & #logo-black {
      display: none;
    }

    & #logo-white {
      display: block;
    }
  }

  .header__color {
    & .active .menu__list {
      color: $white;
    }

    & .menu__icon {

      &::after,
      &::before,
      & span {
        background-color: $text-color;
      }
    }

    & .menu__icon.active {

      &::after,
      &::before,
      & span {
        background-color: $white;
      }
    }
  }
}

//Подложка при прокрутке страницы
.scrolled {
  background: $bg-gradient;
  backdrop-filter: blur(10px);
}

.header {
  color: $white;
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 22px 0px 16px;
  }

  @media (max-width: $tablet) {
    height: 60px;
    align-items: center;
    display: flex;

    &__body {
      align-items: center;
      padding: 0px 16px 0px 4px;
    }
  }

  @media (max-width: $mobile) {
    &__logo {
      width: 113.82px;
    }
  }
}

.menu {
  &__body {
    display: flex;
    column-gap: 151px;
    padding-top: 5px;
  }

  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    column-gap: 37px;

    & a {
      position: relative;
    }

    & a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: $white;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/
    }

    @media(hover:hover) {

      a:hover:after,
      a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
      }
    }
  }

  &__login {
    display: flex;
    color: $blue;
    column-gap: 54px;
    transition: text-decoration .3s;

    & a {
      position: relative;
    }

    & a::after {
      display: block;
      position: absolute;
      left: 0;
      /*изменить на right:0;, чтобы изменить направление подчёркивания */
      width: 0;
      /*задаём длинну линии до наведения курсора*/
      height: 2px;
      /*задаём ширину линии*/
      background-color: $blue;
      /*задаём цвет линии*/
      content: "";
      transition: width 0.3s ease-out;
      /*задаём время анимации*/
    }

    @media(hover:hover) {

      a:hover:after,
      a:focus:after {
        width: 100%;
        /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
      }
    }
  }

}

@media (max-width: $desktop) {
  .menu__body {
    column-gap: 100px;
  }

  .menu__login {
    column-gap: 25px;
  }

  .menu__list {
    column-gap: 20px;
  }
}



@media (min-width: $tablet) {
  .menu__connect {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -14.5px;
      height: 14px;
      width: 2px;
      background-color: $white;
      opacity: .3;
    }
  }

  .menu__support {
    display: none;
  }
}

@media (min-width: $desktop) {

  .menu__connect {
    &::before {
      right: -27px;
    }
  }

  .menu__support {
    display: none;
  }
}

@media (max-width: $tablet) {
  .header__logo {
    z-index: 5;
  }

  .menu__icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 12px;
    height: 19px;
    cursor: pointer;

    & span,
    &::after,
    &::before {
      left: 0;
      position: absolute;
      height: 3px;
      width: 100%;
      transition: all .3s;
      background-color: $white;
    }

    &::after,
    &::before {
      content: '';
    }

    &::after {
      top: 0;
      width: 200%;
    }

    &::before {
      bottom: 0;
    }

    & span {
      top: 50%;
      transform: scale(1) translate(0px, -50%);
    }

    &.active {
      & span {
        transform: scale(0) translate(0px, -50%);
      }

      &:before {
        width: 200%;
        bottom: 50%;
        transform: rotate(45deg) translate(0px, 50%);
      }

      &::after {
        top: 49%;
        transform: rotate(-45deg) translate(0px, -50%);
      }
    }

  }

  .menu__body {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    @include heightVH;
    padding: 120px 0px 36px;
    background: $bg-gradient;
    backdrop-filter: blur(10px);
    transition: left .3s;
    z-index: 4;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: auto;

    &.active {
      left: 0;
    }
  }

  .menu__list {
    flex-direction: column;
    color: $white;
    row-gap: 24px;
    text-align: center;
    font-size: 20px;
  }

  .menu__login {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 24px;

    //----Доп класс на мобилке
    & .btn {
      font-size: 16px;
      width: 303px;
    }
  }

  .menu__support {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    padding: 36px 0px;
    margin-top: 16px;
    position: relative;

    &>a {
      color: $blue;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 48px;
      height: 1px;
      background-color: $white;
      opacity: .3;
    }
  }

  .menu__img {
    display: flex;
    gap: 24px;
  }
}

@media (max-height: 620px) and (max-width: $mobile-sm) {
  .menu__body {
    padding: 60px 0px 26px;
  }

  .menu__list {
    row-gap: 20px;
  }

  .menu__support {
    row-gap: 20px;
    padding: 24px 0px;
    margin-top: 4px;
  }

  .menu__login {
    row-gap: 20px;
  }
}