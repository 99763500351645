.notifications {
  padding: 80px 0px;

  &__body {
    border-radius: 48px;
    display: flex;
    background-color: #F6F8FA;
    padding: 80px 108px;
    column-gap: 11px;
  }

  &__block {
    flex-basis: 61.1%;
  }

  &__title {
    margin-bottom: 24px;
  }
  &__sp {
    color: $blue;
    font-size: 24px;
    line-height: 133%;
    font-weight: 800;
    display: flex;
    margin-bottom: 3px;
  }
  

  &__text {
    color: #728A96;
    max-width: 555px;
    font-size: 20px;
    & span {
      font-size: 2px;
    }
    & br {
      &:nth-child(1) {
        display: none;
      } 
      
    }

    &:not(:last-child) {
      margin-bottom: 47px;
    }
  }

  &__tgs {
    max-width: 396px;
    max-height: 396px;
    display: flex;
  }

  @media (max-width: $desktop) {
    &__body {
      padding: 80px 0px;
      flex-wrap: wrap-reverse;
      align-items: center;
      justify-content: center;
      row-gap: 30px;
    }

    &__block {
      flex-basis: content;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  @media (max-width: $mobile-big) {
    background-color: #F6F8FA;
    padding: 0;
  }

  @media (max-width: $mobile) {
    &__tgs {
      max-width: 247px;
      max-height: 247px;
    }

    &__title {
      margin-bottom: 16px;
    }

    & span {
      font-size: 20px;
      line-height: 150%;
    }

    &__body {
      row-gap: 24px;
      padding: 33px 0px 40px;
    }

    &__text {
      & br {
        &:nth-child(1) {
          display: block;
        }
        display: block;
      }

      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }

    &__button.btn {
      width: 303px;
    }
  }

  @media (max-width: 374px) {
    &__button.btn {
      width: 280px;
    }
  }
}